import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion, AnimatePresence } from 'framer-motion';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ArrowTopRightOnSquareIcon, DocumentDuplicateIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import Nav from 'components/Nav';
import Toggle from 'components/Toggle';
import Tag from 'components/Tag';

export default function CalculadoraPi() {
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [sex, setSex] = useState(false);
  const [hover, setHover] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);

  const handleHover = () => {
    setHover(prev => !prev);
  }

  const handleWeight = (e) => {
    setHeight(e.target.value);
    handleCalc(e.target.value, sex);
  }

  const handleSex = (e) => {
    setSex(e.target.checked);
    handleCalc(height, e.target.checked);
  }

  const handleCalc = (height, sex) => {
    if (height > 149) {
      const ec1 = height - 150;
      const ec2 = ec1 / 2.5;

      let ec3;
      let ec4;

      if (sex) {
        ec3 = ec2 * 2.72;
        ec4 = ec3 + 47.7;
      } else {
        ec3 = ec2 * 2.27;
        ec4 = ec3 + 45.5;
      }

      setWeight(Math.round(ec4 * 10) / 10);
    }
  }

  const handleCopy = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Gisela Gonzalez - Nutrición - Calculadora PI</title>
          <meta property="og:title" content="Calculadora de peso ideal" />
          <meta property="og:site_name" content="Gisela Gonzalez - Nutrición" />
          <meta property="og:url" content="https://giselagonzalez.com" />
        </Helmet>
      </HelmetProvider>
      <Nav />
      <main>
        <h1 className="mb-2">Calculadora de peso ideal</h1>
        <h2 className="flex gap-1">
          Calculadora de peso ideal
          <div className="flex flex-row items-center gap-1">
            <a href="https://en.wikipedia.org/wiki/Human_body_weight#Hamwi_method" target="_blank" className="underline">Ecuación de Hamwi</a>
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-500" />
          </div>
        </h2>
        <div className="flex flex-col md:flex-row bg-white rounded-xl mt-10 shadow-xl">
          <div className="flex flex-col gap-4 md:w-2/3 p-10">
            <div className="flex flex-col">
              <label htmlFor="height">Altura (cm)</label>
              <input type="number" min={150} name="height" id="height" onChange={handleWeight} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="sex">Genero</label>
              <div>
                <Toggle onChange={handleSex} />
              </div>
            </div>
          </div>
          <div
            className="relative flex flex-col justify-center bg-gradient p-10 grow"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}>
            <AnimatePresence>
              {hover && weight > 0 && (
                <CopyToClipboard text={weight} onCopy={handleCopy}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute flex flex-col gap-3 items-center justify-center top-0 left-0 rounded-b-xl lg:rounded-r-xl bg-black bg-opacity-50 w-full h-full cursor-pointer">
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}>
                      <DocumentDuplicateIcon className={`h-10 w-10 text-white ${copyStatus ? 'text-green-300' : 'text-white'}`} />
                    </motion.div>
                  </motion.div>
                </CopyToClipboard>
              )}
            </AnimatePresence>
            <span className="uppercase">
              peso ideal
            </span>
            <span className="font-mono text-[80px]">
              {weight}<span className="text-sm">kg</span>
            </span>
            <div className="flex gap-2">
              <Tag text={sex ? 'masculino' : 'femenino'} />
              <Tag text={(height / 100) + ' m'} />
            </div>
            <div className="flex items-center gap-1 border-t border-beige mt-4 py-4">
              <ArrowRightIcon className="h-3 w-3 text-brand-gray" />
              <span className="text-xs text-brand-gray">
                {(Math.round((weight * 0.8) * 100 )) / 100} gr de proteínas (paciente renal)
              </span>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}