import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import 'assets/styles/index.css';
import Home from 'routes/home';
import CalculadoraPi from 'routes/calculadora-pi';
import CalculadoraPic from 'routes/calculadora-pic';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/calculadora-pi',
    element: <CalculadoraPi />
  },
  {
    path: '/calculadora-pic',
    element: <CalculadoraPic />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
