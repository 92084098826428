import logo from 'assets/images/logo.svg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

export default function Home() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Gisela Gonzalez - Nutrición - Home</title>
        </Helmet>
      </HelmetProvider>
      <div className="h-full w-full flex flex-col gap-10 items-center justify-center">
        <div className="flex flex-col gap-2 items-center justify-center">
          <img src={logo} alt="Gisela Gonzalez Nutrición" width={300} height="auto" />
        </div>
        <div className="flex flex-row items-center justify-center gap-2 bg-brand-gray rounded-full px-5 py-3 text-white text-xs shadow-brand-gray">
          <EnvelopeIcon className="h-4 w-4 text-white" />
          <a href="mailto:consulta@giselagonzalez.com">consulta@giselagonzalez.com</a>
        </div>
      </div>
    </>
  );
}