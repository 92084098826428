import React from 'react'

const Toggle = ({ onChange, checked }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer -mb-6">
      <input type="checkbox" value="" checked={checked} className="sr-only peer focus:outline-none" onChange={onChange} />
      <div className="w-11 h-6 bg-pink-200 peer-focus:outline-none 
        rounded-full peer peer-checked:after:translate-x-full
        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
        after:left-[2px] after:bg-white after:rounded-full after:h-5
        after:w-5 after:transition-all peer-checked:bg-sky-200"></div>
    </label>
  )
}

export default Toggle